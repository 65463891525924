import 'es7-object-polyfill';
import 'react-app-polyfill/ie11';
import 'polyfill-array-includes';
import 'polyfill-object.fromentries';
import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import smoothscroll from 'smoothscroll-polyfill';

import useWindowDimensions from './components/hooks/window_dimensions'
import MobileViewContext from './mobile_view_context';

import Layout from './components/layout/Layout';

import { DONATE_URL, SUPPORT_SERVER } from './config';

/* Import all CSS in index */
import './css/due-style.css';
import './css/new-due-style.css';
import './css/discord-embeds.css';
import './components/home/home.css'
import './components/home/donate-button.css';
import './components/vote/vote.css'
import './components/layout/guide.css';
import './components/layout/header.css';
import './components/commands/commands.css';
import 'rc-tooltip/assets/bootstrap.css';

/* MUST!!! load media queries last! */
import './css/due-media-queries.css';
import './components/vote/vote-media-queries.css';

const Guide = lazy(() => import('./components/guide/Guide'));
const Home = lazy(() => import('./components/home/Home'));
const Commands = lazy(() => import('./components/commands/Commands'));
const Vote = lazy(() => import('./components/vote/Vote'));
const Page404 = lazy(() => import('./components/404'));
// const QuestMaker = lazy(() => import('./components/due_maker/QuestMaker'));
const PageUnderConstruction = lazy(() => import('./components/UnderConstruction'));

const MIN_DESKTOP_WIDTH = 970;

// Fixes the site on smelly old browsers >:(
smoothscroll.polyfill();

const redirect = (url) => {
  window.location.href = url;
  return null;
};

const Index = () => {
  const { width } = useWindowDimensions();
  const mobile = width < MIN_DESKTOP_WIDTH;

  return (
    <Router>
      <MobileViewContext.Provider value={mobile}>
        <Layout>
          {/* Having nothing while it loads is less jarring*/}
          <Suspense fallback={<div />}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/guide">
                <Guide />
              </Route>
              <Route path={`/commands`}>
                <Commands />
              </Route>
              <Route path="/vote">
                <Vote />
              </Route>
              {/* <Route path="/questmaker">
                <QuestMaker/>
              </Route> */}
              <Route
                path="/invite"
                render={() => redirect("https://invite.dueutil.tech")}
              />
              <Route
                path="/donate"
                render={() => redirect(DONATE_URL)}
              />
              <Route
                path="/support"
                render={() => redirect(SUPPORT_SERVER)}
              />
              <Route path="/construction">
                <PageUnderConstruction />
              </Route>
              <Route path="*" component={Page404} />
            </Switch>
          </Suspense>
        </Layout>
      </MobileViewContext.Provider>
    </Router>
  );
}

const rootElement = document.getElementById("root");

render(<Index />, rootElement);
