import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header, { HeaderItem } from './Header';
import { addBackToTop } from 'iaso-vanilla-back-to-top';
import { SUPPORT_SERVER, INVITE_URL, EMAIL, DONATE_URL } from '../../config';

const mainContent = React.createRef();

const Layout = ({ children }) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    addBackToTop({
      scrollContainer: document.getElementById("main-content"),
      diameter: 56,
      backgroundColor: '#FE9268',
      textColor: '#fff'
    })
  }, [])

  useEffect(() => {
    if (hash && document.getElementById(hash.replace('#', ''))) {
      return;
    }
    document.getElementById("main-content").scrollTo(0, 0);
  }, [pathname, hash])

  return (
    <React.Fragment>
      <Header content={mainContent}>
        <HeaderItem href="/">Home</HeaderItem>
        <HeaderItem href="/commands">Commands</HeaderItem>
        <HeaderItem href="/guide">Guide</HeaderItem>
        <HeaderItem href={SUPPORT_SERVER}>Support Server</HeaderItem>
        <HeaderItem href="/vote">Vote</HeaderItem>
        <HeaderItem href={DONATE_URL}>Donate</HeaderItem>
        <HeaderItem href={INVITE_URL}>Invite DueUtil</HeaderItem>
      </Header>
      <div id="main-content" ref={mainContent}>
        {children}
        <footer>
          <div><a href={`mailto:${EMAIL}`}>Contact</a>{' '}
            <span className="smol-due-logo" href="/">DUeutil</span>{' '}
            <a href={DONATE_URL}>Donate</a></div>
          <div id="footer-credits">ToadKing#4674 | <a href="https://github.com/MacDue">MacDue#4453</a> | <a href="https://pixeljoint.com/p/171253.htm">Argo#1303</a><br />
            <span id="footer-credits__ana">& Ru#0002</span></div>
        </footer>
      </div>
    </React.Fragment>);
}

export default Layout;
