import React, { useContext } from 'react';
import { Link, withRouter } from "react-router-dom"
import hrefType from 'href-type';
import { INVITE_URL } from '../../config';

import MobileViewContext from '../../mobile_view_context';
import PopupLink from '../PopupLink';

const DUEUTIL_TEXT_LOGO = "DUeutil"

export const HeaderItem = () => { };

const renderHref = (href, children, desktop = true) => {
  const linkType = hrefType(href);
  const isReactRouter = linkType === "relative" || linkType === "rooted";
  let className = !desktop ? "row-content" : "button-content__desktop";
  if (isReactRouter) {
    return <Link className={className} to={href}>{children}</Link>
  }
  // Open external links in new tab
  if (desktop && href === INVITE_URL) {
    className += " button-hl";
  }
  return <PopupLink className={className} href={href}>{children}</PopupLink>;
}

const HeaderButton = (props) => (
  <div className="button-container__desktop">
    {renderHref(props.href, props.children)}
  </div>)

const renderHeaderItems = (headerItems) => {
  return headerItems.map(({ props }, key) => <HeaderButton key={key} {...props} />);
}

const renderMobileHeaderItems = (headerItems) => {
  return headerItems.map((item, key) =>
    <div className={item.props.href === INVITE_URL ? "row-container hl" : "row-container"} key={"row-" + key}>
      {renderHref(item.props.href, item.props.children, false)}
    </div>
  ).reduce((prev, curr, key) =>
    [prev, <div key={"sep-" + key} className="header__dropdown__spacer" />, curr])
}

class MobileHeader extends React.Component {
  state = { dropDownOpen: false }
  headerRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      setTimeout(() => {
        this.setState({ dropDownOpen: false });
      }, 100);
    }
    const body = this.props.content.current;
    const scroll = this.scrollLocks();
    if (!this.state.dropDownOpen && prevState.dropDownOpen) {
      scroll.unlock();
      body.onclick = null;
    } else if (this.state.dropDownOpen && !prevState.dropDownOpen) {
      scroll.lock();
      body.onclick = this.toggleDropdown;
    }
  }

  scrollLocks = () => {
    const bodyStyle = this.props.content.current.style;
    const headerHeight = this.headerRef.current.offsetHeight;
    return {
      lock: () => {
        bodyStyle.top = `${headerHeight - window.scrollY}px`;
        bodyStyle.position = 'fixed';
        bodyStyle.overflowY = 'hidden';
      },
      unlock: () => {
        const scrollY = bodyStyle.top;
        bodyStyle.position = '';
        bodyStyle.overflowY = 'auto';
        bodyStyle.top = '';
        window.scrollTo(0, -parseInt(scrollY || '0') + headerHeight);
      }
    }
  }

  toggleDropdown = (e) => {
    this.setState(prevState => ({
      ...prevState,
      dropDownOpen: !prevState.dropDownOpen
    }))
    if (e) { e.preventDefault(); }
  }

  componentWillUnmount = () => {
    this.scrollLocks().unlock();
  }

  render = () => (
    <div id="header">
      <header ref={this.headerRef}>
        <div className="button-container__mobile">
          <button
            className="button-content button-content__mobile"
            onClick={this.toggleDropdown}>
            <span className="material-icons">menu</span></button>
        </div>
        <Link to="/"><h1 id="mobile-logo">{DUEUTIL_TEXT_LOGO}</h1></Link>
        <div id="header__mobile_dummy" className="button-container__mobile">
        </div>
      </header>
      <div id="header__dropdown__wrapper"
        className={!this.state.dropDownOpen ? "hide-dropdown" : undefined}>
        <div id="header__dropdown"
          className={this.state.dropDownOpen ? "slide-in" : undefined}>
          <div>
            {renderMobileHeaderItems(this.props.headerItems)}
          </div>
        </div>
      </div>
    </div>
  )
}
MobileHeader = withRouter(MobileHeader);

const Header = ({ children, content }) => {
  if (!Array.isArray(children))
    children = [children]

  const mobile = useContext(MobileViewContext);

  return !mobile
    ? <div id="header">
      <header>
        <Link to="/"><h1 id="logo__desktop" key="logo">{DUEUTIL_TEXT_LOGO}</h1></Link>

        <div id="header-item-list">
          {renderHeaderItems(children)}
        </div>
      </header>
    </div>
    : <MobileHeader content={content} headerItems={children} />;
};

export default Header;
